<template>
  <div>
    <div class="main">
      <Card style="width: 25em">
        <template #content>
          <div class="card-content">
            <div class="flex-item">
              <Button label="Login with Sales Force" @click="login" />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import authService from "../service/authService";
export default {
  mounted() {
    let query = Object.assign({}, this.$route.query);
    let token = query.t;
    if (token) {
      authService.setToken(token);
      this.$router.push("/");
    }
  },
  watch: {},
  methods: {
    login() {
      authService.login();
    },
  },
};
</script>
<style scoped>
div .main {
  display: flex;
  justify-content: center;
}
div .card-content {
  display: flex;
  justify-content: center;
}
</style>
